import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import * as s from './styles/globalStyles'
import styled from 'styled-components'
import { Variants, motion } from 'framer-motion/dist/framer-motion'
import Socials from './components/Socials'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: var(--button-border-radius);
  border: var(--button-border-style);
  border-color: var(--button-border-color);
  border-width: var(--button-border-width);
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-family: var(--font-family-button);
  color: var(--button-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  :hover {
    background-color: var(--button-hover-color);
    color: var(--button-text-hover-color);
    border-color: transparent;
  }
  :hover:after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #fc7600, #ff0011, #5073b8, #1098ad, #6fba82);
    height: calc(100% + 3px * 4);
    width: calc(100% + 3px * 4);
    top: calc(-3px * 2);
    left: calc(-3px * 2);
    z-index: -1;
    border-radius: 15px;
    background-size: 200%;
    animation: animate 1s ease infinite alternate;
}
@keyframes  animate {
  from {
      background-position: 0% 50%;
  }
  to {
      background-position: 100% 50%;
  }
}
  :active {
    background-color: var(--button-active-color);
  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--round-button-bg);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  font-family: var(--font-family);
  color: var(--round-button-fg);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));

  :hover {
    background-color: var(--button-hover-color);
    color: var(--button-text-hover-color);
    border-color: transparent;
  }
  :hover:after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #fc7600, #ff0011, #5073b8, #1098ad, #6fba82);
    height: calc(100% + 3px * 4);
    width: calc(100% + 3px * 4);
    top: calc(-3px * 2);
    left: calc(-3px * 2);
    z-index: -1;
    border-radius: 100%;
    background-size: 200%;
    animation: animate 1s ease infinite alternate;
}
@keyframes  animate {
  from {
      background-position: 0% 50%;
  }
  to {
      background-position: 100% 50%;
  }
}
  :active {
    background-color: var(--button-active-color);
  }
`

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const ResponsiveWrapper2 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20rem;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`

export const StyledImg = styled.img`
  box-shadow: var(--shadow-offset-x) var(--shadow-offset-y)
    var(--shadow-blur-radius) var(--shadow-spread-radius)
    rgba(var(--shadow-r), var(--shadow-g), var(--shadow-b), var(--shadow-alpha));
  border: var(--border-width-gif) var(--border-style-gif)
    var(--border-color-gif);
  background-color: var(--accent2);
  border-radius: var(--secondary-corner-radius);
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 350;
  }
  transition: width 0.5s;
`

export const StyledImg2 = styled.img`
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 350;
  }
  transition: width 0.5s;
`

export const StyledImgFlipped = styled(StyledImg)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const StyledImgMobile = styled(StyledImg)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledImgFlippedMobile = styled(StyledImgFlipped)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledLink = styled.a`
  color: var(--link);
  background: -webkit-linear-gradient(0deg, var(--text-grad1), var(--text-grad2), var(--text-grad3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  :hover {
    color: var(--link-hover);
    background: -webkit-linear-gradient(0deg, var(--text-grad3), var(--text-grad2), var(--text-grad1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

export const StyledLink2 = styled.a`
  color: var(--link);
  background: -webkit-linear-gradient(90deg, var(--text-grad3), var(--text-grad3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  :hover {
    color: var(--link-hover);
    background: -webkit-linear-gradient(6deg, var(--text-grad2), var(--text-grad1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const Preview = (props) => {
  if ( props.side == "left" && props.flip == "left" ) {
    return (<StyledImgFlippedMobile alt={'example'} src={'/config/images/example1.gif'} />)
  } else if ( props.side == "left" && props.flip != "left") {
    return (<StyledImgMobile alt={'example'} src={'/config/images/example1.gif'} />)
  } else if ( props.side == "right" && props.flip == "right" ) {
    if ( !props.mobile ) {
      return (<StyledImgFlipped alt={'example'} src={'/config/images/example2.gif'} />)
    } else {
      return (<StyledImgFlippedMobile alt={'example'} src={'/config/images/example2.gif'} />)
    }
  } else if ( props.side == "right" && props.flip != "right" ) {
    if ( !props.mobile ) {
      return (<StyledImg alt={'example'} src={'/config/images/example2.gif'} />)
    } else {
      return (<StyledImgMobile alt={'example'} src={'/config/images/example2.gif'} />)
    }
  } else {
    return (<StyledImgMobile alt={'example'} src={'/config/images/example1.gif'} />)
  }
}

function App () {
  const dispatch = useDispatch()
  const blockchain = useSelector(state => state.blockchain)
  const data = useSelector(state => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    TELEGRAM: '',
    TWITTER: '',
    FACEBOOK: '',
    DISCORD: '',
    OTHERSOCIAL: '',
    OTHERTWO: '',
    SHOW_BACKGROUND: false,
    TWO_PREVIEW_MOBILE: true,
    PREVIEWFLIP: "none"
  })

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String(cost * mintAmount)
    let totalGasLimit = String(gasLimit * mintAmount)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)

    
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      })
      .once('error', err => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then(receipt => {
        console.log(receipt)
        setFeedback(
          `The ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > 20) {
      newMintAmount = 20
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData()
  }, [blockchain.account])

  const previewVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)', backgroundRepeat: 'repeat' }}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/bg.png' : null}
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8, rotate: -180 }}
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            default: {
              duration: 0.6,
              ease: [0, 0.71, 0.2, 1.01]
            },
            scale: {
              type: "spring",
              damping: 8,
              stiffness: 300,
              restDelta: 0.001
            }
          }}
        >
          <StyledLogo alt={'logo'} src={'/config/images/logo.gif'} />
        </motion.div>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <motion.div
              whileHover={{ scale: 1.1, rotate: -10 }}
              whileTap={{ scale: 1.2, rotate: 10 }}
              transition={{ type: "spring", stiffness: 400 }}
              initial="offscreen"
              animate="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={previewVariants}
            >
              <Preview side='left' flip={CONFIG.PREVIEWFLIP} mobile={CONFIG.TWO_PREVIEW_MOBILE} />
            </motion.div>
          </s.Container>
          <s.SpacerLarge />
          <motion.div           
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.2,
            default: {
              duration: 0.6,
              ease: [0, 0.71, 0.2, 1.01]
            },
          }}>
          <s.MiddleContainer
            flex={2}
            jc={'center'}
            ai={'center'}
            style={{
              backgroundColor: 'var(--accent)',
              padding: 24,
              borderRadius: 'var(--primary-corner-radius)',
              border:
                'var(--border-width) var(--border-style) var(--border-color)',
              borderImage: 'linear-gradient(0deg, #12c2e9, #aa00ff, #ff0011) 30',
              boxShadow:
                'var(--shadow-offset-x) var(--shadow-offset-y) var(--shadow-blur-radius) var(--shadow-spread-radius) rgba(var(--shadow-r),var(--shadow-g),var(--shadow-b),var(--shadow-alpha))'
            }}
          >
            <s.TextTitle
              style={{
                textAlign: 'center',
                fontSize: 50,
                fontWeight: 'bold',
                fontFamily: 'var(--font-family)',
                color: 'var(--accent-text)'
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: 'center',
                color: 'var(--primary-text)'
              }}
            >
              <StyledLink target={'_blank'} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 30)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{' '}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === '' ||
                blockchain.smartContract === null ? (
                  <s.Container ai={'center'} jc={'center'}>
                    <s.TextDescription
                      style={{
                        textAlign: 'center',
                        color: 'var(--accent-text)'
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <StyledButton
                        onClick={e => {
                          e.preventDefault()
                          dispatch(connect())
                          getData()
                        }}
                      >
                        CONNECT
                      </StyledButton>
                    </motion.div>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: 'center',
                            color: 'var(--accent-text)'
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: 'center',
                        color: 'var(--accent-text)'
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={'center'} jc={'center'} fd={'row'}>
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault()
                            decrementMintAmount()
                          }}
                        >
                          -
                        </StyledRoundButton>
                      </motion.div>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: 'center',
                          color: 'var(--accent-text)'
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault()
                            incrementMintAmount()
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </motion.div>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={'center'} jc={'center'} fd={'row'}>
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault()
                            claimNFTs()
                            getData()
                          }}
                        >
                          {claimingNft ? 'BUSY' : 'BUY'}
                        </StyledButton>
                      </motion.div>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.MiddleContainer>
          </motion.div>
          <s.SpacerLarge />
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <motion.div
              whileHover={{ scale: 1.1, rotate: -10 }}
              whileTap={{ scale: 1.2, rotate: 10 }}
              transition={{ type: "spring", stiffness: 400 }}
              initial="offscreen"
              animate="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={previewVariants}
            >
              <Preview side='right' flip={CONFIG.PREVIEWFLIP} mobile={CONFIG.TWO_PREVIEW_MOBILE} />
            </motion.div>
          </s.Container>
        </ResponsiveWrapper>
        <Socials telegram={CONFIG.TELEGRAM} facebook={CONFIG.FACEBOOK} discord={CONFIG.DISCORD} twitter={CONFIG.TWITTER} other={CONFIG.OTHERSOCIAL} othertwo={CONFIG.OTHERTWO} />
        
      </s.Container>
      <s.Container
        flex={1}
        ai={'center'}
        jc={'center'}
        style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 8, backgroundColor: 'black', backgroundRepeat: 'repeat' }}
      >
<s.Container flex={2} ai={'center'} jc={'center'} style={{ maxWidth: '600px', paddingTop: '0px' }}>
<s.TextBtmTitle
  style={{
    textAlign: 'center',
  }}
>
  Welcome to Dork Wadz!
</s.TextBtmTitle>
<s.SpacerSmall />
<s.TextDescription>
  Here you can mint your one of a kind Dork Wadz NFT's from BoneWhale Project!
</s.TextDescription>
<s.SpacerLarge />
<s.TextBtmFaq
  style={{
    textAlign: 'center',
  }}
>
  FAQ:
</s.TextBtmFaq>
<s.SpacerMedium />
<s.TextBtmQ>
  How much does minting a Dork Wadz NFT Cost?
</s.TextBtmQ>
<s.SpacerSmall />
<s.TextBtmA>
  Normally it costs 6 BONE however from time to time there may be sales and special events with lower prices!
</s.TextBtmA>

<s.SpacerMedium />
<s.TextBtmQ>
  Giveaways Every 250 Mints!
</s.TextBtmQ>
<s.SpacerSmall />
<s.TextBtmA>
  For every 250 Dork Wadz minted we will give away a prize to a random minter out of the batch of 250! For more information join our <StyledLink2 target={'_blank'} href={CONFIG.TELEGRAM}>Telegram Group!</StyledLink2>
</s.TextBtmA>

<s.SpacerMedium />
<s.TextBtmQ>
  Where can I view and trade my NFT?
</s.TextBtmQ>
<s.SpacerSmall />
<s.TextBtmA>
  Great question young interlocuteur, you can view sell and trade your Dork Wadz on the great <StyledLink2 target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>{CONFIG.MARKETPLACE} Marketplace!</StyledLink2>
</s.TextBtmA>

<s.SpacerMedium />
<s.TextBtmQ>
  DISCLAIMER:
</s.TextBtmQ>
<s.SpacerSmall />
<s.TextBtmA>
Once you mint a Dork Wadz NFT, the action cannot be undone. Dork Wadz are not to be considered a financial asset or an investment so proceed with caution.
</s.TextBtmA>

<s.SpacerLarge />
<StyledImg2 alt={'example'} src={'/config/images/whalecum.gif'} />
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}

export default App
